import { ChainId } from '@pancakeswap-libs/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  // [ChainId.MAINNET]: '0xf6488205957f0b4497053d6422F49e27944eE3Dd', // TODO
  [ChainId.BSCTESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
  [ChainId.MAINNET]: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d',
  [ChainId.MATICTEST]: '0xBBEa47c3746912809c554b38b426f43ABA411eec',

}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
